<template>
  <div v-loading="loading" class="outerLayer">
    <template v-if="!loading">
      <div class="mod-ucenter-score flex flex-a_c flex-j_between" :class="percentClass">
        <div class="mod-ucenter-score-main">
          <p v-if="!sfwc" class="mod-ucenter-score-number">{{ percentage }}%</p>
          <div class="mod-ucenter-score-desc">
            <div v-if="sfwc">
              <div v-if="spjg === 'SUCCESS'">
                <div class="fs16 colorFFFFFF">恭喜您审核通过</div>
              </div>
              <div v-if="spjg === 'ERROR'">
                <div class="fs16 colorFFFFFF">根据下方详情提示返回上一步修改</div>
                <p class="fs14 mt20">
                  <span
                    >共审核<span class="fontW fs16">{{ zjysl }}</span
                    >项</span
                  >
                  |
                  <span
                    >其中通过<span class="fontW fs16">{{ zqsl }}</span
                    >项</span
                  >
                  |
                  <span
                    >不通过<span class="fontW fs16">{{ cwsl }}</span
                    >项</span
                  >
                </p>
              </div>
              <div v-if="spjg === 'WARNNING'">
                <div class="fs16 colorFFFFFF">信息匹配失败，需转人工审核</div>
                <p class="fs14 mt20">人工审核完成后将即使通知您，请耐心等待！</p>
              </div>
            </div>
            <p v-else class="fs14 mt20">请稍等...</p>
          </div>
        </div>
        <div v-if="sfwc" class="fs16 colorFFFFFF">
          <i v-if="spjg === 'SUCCESS'" class="el-icon-success fs26 mr10 colorFFFFFF"></i>
          <i v-if="spjg === 'ERROR'" class="el-icon-error fs26 mr10 colorFFFFFF"></i>
          <i v-if="spjg === 'WARNNING'" class="el-icon-warning fs26 mr10 colorFFFFFF"></i>
          {{ spjg === 'SUCCESS' ? '通过' : '不通过' }}
        </div>
      </div>
      <!--  内容  -->
      <div class="contentZnsp mt40">
        <div v-for="(item, index) in Object.keys(activities)" :key="index" class="lineBox">
          <div>
            <div class="flex flex-a_c flex-j_between parentBox">
              <div class="titleParent">
                <i class="mr10 color2C8BFF el-icon-eleme"></i>
                <span>{{ item }}</span>
              </div>
            </div>
            <ul class="childBox">
              <li v-for="(itemS, indexS) in activities[item]" :key="indexS" class="flex flex-a_c flex-j_between">
                <div>
                  <span class="color333333">{{ itemS.jynr }}</span>
                  <span class="color9B9B9B">{{ itemS.jtxx }}</span>
                </div>
                <div v-if="itemS.jyjg === 'SUCCESS'">
                  <i class="el-icon-success mr10 color7ED421"></i>
                  <span>通过</span>
                </div>
                <div v-if="itemS.jyjg === 'ERROR'">
                  <i class="el-icon-error mr10 colorRed"></i>
                  <span>未通过</span>
                </div>
                <div v-if="itemS.jyjg === 'WARNNING'">
                  <i class="el-icon-warning mr10 colorOrange"></i>
                  <span>警告</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </template>
    <no-data v-if="loading || !Object.keys(activities)" :data="dataNo"> </no-data>
    <div v-if="spjg === 'SUCCESS'" class="main-footer text-center">
      <el-button type="primary" @click="prev()">上一步</el-button>
      <el-button type="primary" @click="next()">下一步</el-button>
    </div>
    <div v-if="spjg === 'ERROR'" class="main-footer text-center">
      <el-button type="primary" @click="$router.back()">上一步</el-button>
      <el-button type="primary" @click="goStep('home-login-out')">返回首页</el-button>
    </div>
    <div v-if="spjg === 'WARNNING'" class="main-footer text-center">
      <!--个人中心--我的申请-->
      <el-button type="primary" @click="goStep('user-center')">我的申请</el-button>
      <el-button type="primary" @click="goStep('home-login-out')">返回首页</el-button>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
import applicantFinishService from '@/pages/iebdc/api/apply/applicant-finish';
let step = 0;
let timer = null;
export default {
  name: 'intelligentAudit',
  data() {
    return {
      percentage: 0,
      percentClass: 'mod-center-verify-60',
      activities: [],
      spjg: '', //查询结果之后的状态
      jylx: '', //当前查询的内容
      sfwc: false, //是否完成
      zjysl: 0, //总校验数量
      zqsl: 0, //通过数量
      cwsl: 0, //错误数量
      loading: true,
      dataNo: {
        src: require('@/pages/iebdc/assets/images/znspImg@2x.png'),
        title: '暂无数据',
        description: '请重新上传材料...'
      }
    };
  },
  computed: {
    ...mapGetters(['selectedBsdtCode']),
    // 下一步跳转所需数据
    ...mapState('wysq-step', ['nextItem', 'prevItem']),
    ...mapState('wysq-data', ['flowInfoIng'])
  },
  created() {
    this.targetToStep('信息核验');
    this.intelligentApproval();
    timer = setTimeout(() => {
      this.getGroupByYwh();
    }, 100);
  },
  destroyed() {
    clearTimeout(timer);
  },
  methods: {
    // 跳转到下一页面
    ...mapMutations('wysq-step', ['targetToStep']),
    intelligentApproval() {
      this.loading = true;
      if (!this.flowInfoIng.id) return;
      applicantFinishService
        .completeApplyResult({
          qlxxId: this.flowInfoIng.id
        })
        .then(() => {})
        .catch(() => {
          this.loading = false;
        });
    },
    //获取智能审批结果
    getGroupByYwh() {
      if (!this.flowInfoIng.wwywh) return;
      let param = {
        ywh: this.flowInfoIng.wwywh
      };
      applicantFinishService
        .getGroupByYwh(param)
        .then((res) => {
          if (res.success) {
            if (res.data) {
              this.percentage = res.data.gzjd;
              this.activities = res.data.result;
              this.sfwc = res.data.sfwc;
              this.spjg = res.data.spjg;
              this.zjysl = res.data.zjysl;
              this.zqsl = res.data.zqsl;
              this.cwsl = res.data.cwsl;
              if (!this.sfwc) {
                let arrClass = ['mod-center-verify-60', 'mod-center-verify-80', 'mod-center-verify-100'];
                this.percentClass = arrClass[Math.floor(Math.random() * 3)];
                timer = setTimeout(() => {
                  this.getGroupByYwh();
                }, 500);
              } else {
                if (this.spjg === 'SUCCESS') {
                  this.percentClass = 'mod-center-verify-100';
                } else if (this.spjg === 'ERROR') {
                  this.percentClass = 'mod-center-verify-60';
                } else {
                  this.percentClass = 'mod-center-verify-80';
                }
              }
            } else {
              step++;
              if (step < 60) {
                timer = setTimeout(() => {
                  this.getGroupByYwh();
                }, 500);
              }
              console.log(step, 'step===');
            }
          } else {
            this.$message.error(res.message);
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    goStep(name) {
      //跳转
      this.$router.push({
        name: name
      });
    },
    //上一步
    prev() {
      this.targetToStep('信息核验');
      this.$router.push({
        name: this.prevItem.name,
        params: {
          ywh: this.flowInfoIng.wwywh
        }
      });
    },
    // 跳转到下一页面,需先校验当前页面数据状态 TODO
    next() {
      this.$confirm('请确认下一步后申请表内容将不可修改, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.targetToStep('信息核验');
          this.$store.commit('wysq-step/getStepList', {
            code: this.flowInfoIng.code,
            step: 'VERIFICATION'
          });
          this.$router.push({
            name: this.nextItem.name
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消下一步操作'
          });
        });
    }
  }
};
</script>

<style scoped lang="scss">
@import 'src/styles/common-variables';
@import 'src/styles/public';

ul {
  list-style: none;
}
.outerLayer {
  i.fs26 {
    vertical-align: middle;
  }
  .mod-ucenter-score {
    height: 92px;
    padding: 0 16px;
    margin-bottom: 10px;
    border-radius: 8px;
    background: #1947ff;
    background-image: -webkit-gradient(linear, left top, right top, from(#1947ff), to(#23a8ff));
    background-image: linear-gradient(90deg, #1947ff, #23a8ff);
  }

  .mod-center-verify {
    background-image: -webkit-gradient(linear, left top, right top, from(#1947ff), to(#23a8ff));
    background-image: linear-gradient(90deg, #1947ff, #23a8ff);
  }

  .mod-center-verify-60 {
    background: #f46036;
    background-image: -webkit-gradient(linear, right top, left top, color-stop(1%, #f46036), to(#e53b36));
    background-image: linear-gradient(270deg, #f46036 1%, #e53b36);
  }

  .mod-center-verify-60 .mod-ucenter-score-btn {
    color: #e53b36;
  }

  .mod-center-verify-80 {
    background: #ffac2f;
    background-image: -webkit-gradient(linear, right top, left top, color-stop(1%, #ffac2f), to(#ea663b));
    background-image: linear-gradient(270deg, #ffac2f 1%, #ea663b);
  }

  .mod-center-verify-80 .mod-ucenter-score-btn {
    color: #ea663b;
  }

  .mod-center-verify-100 {
    background: #2c8cff;
    background-image: -webkit-gradient(linear, left top, right top, from(#2c8cff), to(#24a6ff));
    background-image: linear-gradient(90deg, #2c8cff, #24a6ff);
  }

  .mod-center-verify-100 .mod-ucenter-score-btn {
    color: #2da6ec;
  }

  .mod-ucenter-score-number {
    float: left;
    font-family: DINAlternate-Bold;
    font-size: 60px;
    line-height: 60px;
    color: #fff;
  }

  .mod-ucenter-score-desc {
    float: left;
    margin-left: 12px;
  }

  .mod-ucenter-score-desc p {
    font-size: 12px;
    line-height: 12px;
    color: #fff;
    opacity: 0.8;
  }

  .parentBox {
    padding-bottom: 20px;
  }
  .titleParent {
    font-size: 18px;
    span {
      font-weight: bolder;
      color: #333333;
    }
  }
  .childBox li {
    height: 50px;
    border-top: 1px dashed #e5e5e5;
    span.color333333 {
      display: inline-block;
      width: 300px;
    }
  }
  .childBox li:last-child {
    border-bottom: 1px dashed #e5e5e5;
    margin-bottom: 20px;
  }
}
</style>
